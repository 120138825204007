import { useWindowSize } from '@uidotdev/usehooks'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { JsonLd } from 'react-schemaorg'
import styled from 'styled-components'
import { COLORS, EASINGS, FONT_SIZES, LANDING_ANIMATIONS, PAGE_ANIMATIONS, SIZES, TIME, WORD_MARK_ASPECT_RATIO } from '../../../constants/stylesConstants'
import useElemHeight from '../../../hooks/useElemHeight'
import useLanding from '../../../hooks/useLanding'
import parserServices from '../../../services/parserServices'
import seoServices from '../../../services/seoServices'
import { map } from '../../../utils/commonUtils'
import Size from '../../../utils/helpers/size'
import mixins from '../../../utils/mixins'
import { getVh } from '../../../utils/sizeUtils'
import { extract, styleIf } from '../../../utils/styleUtils'
import LandingCarousel from '../../carousels/landingCarousel'
import Background from '../../common/background'
import FullContainer from '../../common/containers/fullContainer'
import WordMark from '../../common/wordmark'
import HeaderMobile from '../../folio/header/headerMobile'
import LandingInnerContainer from './landingInnerContainer'



const LandingMobile = ({
  menuIsOpened,
  contactIsOpened,
  onMenuToggle,
  onContactToggle
}) => {
  const { width } = useWindowSize()

  const triggerImgRef = useRef()
  const shrinkSpeedRef = useRef(TIME.LANDING_SHRINK_UPPER)
  const enlargeStartRef = useRef()
  const lastShrinkSpeedRef = useRef()
  const [hasShrunk, setHasShrunk] = useState(false)
  const [enlargeEnded, setEnlargeEnded] = useState(true)

  const {
    data,
    heroCopy,
    containerRef,
    handleClick,
    handleLoaded,
  } = useLanding((time, delta) => {
    wordmarkRef.current.style.transform =
      `translate(0, ${getWordMarkTop(hasShrunk).value}px)`

    if (triggerImgRef.current === undefined) return

    const triggerImgTop =
      triggerImgRef.current?.getBoundingClientRect().top + SIZES.LANDING_CAROUSEL_GAP_MOBILE.value

    const min = TIME.LANDING_SHRINK_LOWER
    const max = TIME.LANDING_SHRINK_UPPER
    const shrinkSpeed =
      _.clamp(map(Math.abs(delta), 1, 65, max, min), min, max)

    if (
      enlargeStartRef.current &&
      lastShrinkSpeedRef.current &&
      Math.round((time - enlargeStartRef.current) * 1000) >= lastShrinkSpeedRef.current * 0.5
    ) {
      setEnlargeEnded(true)
      enlargeStartRef.current = undefined
      lastShrinkSpeedRef.current = undefined
    }

    if (!hasShrunk && triggerImgTop <= getVh(35)) {
      shrinkSpeedRef.current = shrinkSpeed
      return setHasShrunk(true)
    }

    if (hasShrunk && triggerImgTop >= getVh(40)) {
      lastShrinkSpeedRef.current = shrinkSpeedRef.current = shrinkSpeed
      enlargeStartRef.current = time
      setEnlargeEnded(false)
      setHasShrunk(false)
    }
  }, [hasShrunk, contactIsOpened, menuIsOpened], contactIsOpened || menuIsOpened)

  const [heroRef, heroHeight] = useElemHeight([data, width])
  const [wordmarkRef, wordmarkHeight] = useElemHeight([data, width])

  const navigate = useNavigate()
  const handleWordMarkClick = () => { if (hasShrunk) navigate(0) }

  const scrollFadeOpacity = hasShrunk || !enlargeEnded ? 0 : 1
  const getTransition = propName => hasShrunk || !enlargeEnded ?
    `${propName} ${EASINGS.CUSTOM} ${shrinkSpeedRef.current}ms` :
    undefined

  return (
    <>
      <Helmet>
        <title>TK Creative | Interior Design</title>
        <meta name='description' content='TK Creative is a design studio, based in Toronto. We design residential and commercial interior environments that transform visions into experiences.' />
      </Helmet>
      <JsonLd item={seoServices.getLandingSchema(data)} />
      <MenuContainer
        $isOpened={menuIsOpened}
        $scrollFadeOpacity={scrollFadeOpacity ^ 1}>
        <HeaderMobile
          className={PAGE_ANIMATIONS[0].cls}
          isOpened={menuIsOpened}
          contactIsOpened={contactIsOpened}
          onContactToggle={onContactToggle}
          onMenuToggle={onMenuToggle}
          isLanding />
      </MenuContainer>
      <Container
        ref={containerRef}
        onClick={handleClick}
        $isOpened={menuIsOpened}>
        <LandingCarousel
          className={LANDING_ANIMATIONS[2].cls}
          heroHeight={heroHeight.current}
          wordmarkHeight={wordmarkHeight.current}
          triggerImgRef={triggerImgRef}
          onLoad={handleLoaded} />
        <InnerContainer>
          <HeroCopy
            ref={heroRef}
            className={LANDING_ANIMATIONS[1].cls}
            $scrollFadeOpacity={scrollFadeOpacity}>
            <span>{parserServices.parseEmDash(heroCopy)}</span>
          </HeroCopy>
        </InnerContainer>
        <WordMarkContainer
          ref={wordmarkRef}
          className={LANDING_ANIMATIONS[0].cls}
          onClick={handleWordMarkClick}
          $hasShrunk={hasShrunk}
          $duration={shrinkSpeedRef.current}
          $botTransition={getTransition('transform')}
          $widthTransition={getTransition('width')}>
          <h1>
            <WordMark />
          </h1>
        </WordMarkContainer>
        <Background color={COLORS.WHITE} />
      </Container>
    </>
  )
}


const scrollFadeTransition = `all ${EASINGS.IN_OUT_QUAD} ${TIME.LANDING_SCROLL_FADE_MOBILE}ms`
const Container = styled(FullContainer)`
  cursor: pointer;
  pointer-events: ${styleIf('$isOpened', 'none')};
  position: relative;
`

const InnerContainer = styled(LandingInnerContainer)`
  display: block;
  width: ${SIZES.MAIN_W_MOBILE.css};
  padding: ${SIZES.MARGIN_MOBILE.css} 0;
`

const HeroCopy = styled.p`
  width: ${SIZES.MAIN_W_MOBILE.css};
  opacity: 0;

  > span {
    opacity: ${extract('$scrollFadeOpacity')};
    transition: ${scrollFadeTransition};
  }
`

const getWordMarkTop = hasShrunk => hasShrunk ?
  SIZES.MARGIN_MOBILE.add(
    FONT_SIZES.REGULAR_MOBILE
      .sub(FONT_SIZES.REGULAR_MOBILE
        .mult(SIZES.WORDMARK_SVG_FACTOR)
      ).div(2)) :
  Size.subFromFullHeight(SIZES.MARGIN_MOBILE)
    .sub(SIZES.MAIN_W_MOBILE.div(WORD_MARK_ASPECT_RATIO))

const WordMarkContainer = styled.div`
  ${mixins.float()}
  position: fixed;
  opacity: 0;
  top: 0;
  display: flex;
  pointer-events: initial;
  transition: ${extract('$botTransition')};

  h1 {
    line-height: 0;
  }

  svg {
    width: ${styleIf('$hasShrunk', FONT_SIZES.REGULAR_MOBILE.mult(SIZES.WORDMARK_SVG_FACTOR).mult(WORD_MARK_ASPECT_RATIO).css, SIZES.MAIN_W_MOBILE.css)};
    transition: ${extract('$widthTransition')};
  }
`

const MenuContainer = styled.div`
  ${mixins
    .chain()
    .highZIndex(4)
    .flex('initial', 'flex-end')}
  position: fixed;
  width: ${SIZES.MAIN_W_MOBILE.css};
  height: ${FONT_SIZES.REGULAR_MOBILE.css};
  margin: ${SIZES.MARGIN_MOBILE.css};

  opacity: ${extract('$scrollFadeOpacity')};
  transition: ${scrollFadeTransition};

  pointer-events: ${styleIf('$isOpened', 'initial', 'none')};
  > header {
    pointer-events: initial;
  }
`

const SmallViewPort = styled.div`
  height: 100svh;
`

export default LandingMobile